/*----------訂閱制頁面----------*/
let Subscription = [
  /*---課程 Start---*/
  {
    path: '/LessonDetailSubscription',
    name: 'LessonDetailSubscription',
    component: () => import('@/views/php/Subscription/LessonDetailSubscription.vue')
  },
  {
    path: '/VideoPlayerSubscription',
    name: 'VideoPlayerSubscription',
    component: () => import('@/views/php/Subscription/VideoPlayerSubscription.vue')
  },
  /*---課程 Start---*/
  /*---電子書 Start---*/
  {
    path: '/EbookLessonSubscription',
    name: 'EbookLessonSubscription',
    component: () => import('@/views/php/Subscription/EbookLessonSubscription.vue')
  },
  {
    path: '/EbookKnowledgeArchitectureSubscription',
    name: 'EbookKnowledgeArchitectureSubscription',
    component: () => import('@/views/php/Subscription/EbookKnowledgeArchitectureSubscription.vue')
  },
  {
    // 前台閱讀
    path: '/EBookClientReviewSubscription',
    name: 'EBookClientReviewSubscription',
    component: () => import('@/views/php/Subscription/EBookClientReviewSubscription.vue')
  },
  /*---電子書 end---*/
  /*---e評量 Start---*/
  { // 題庫
    path: '/ExamRangeSubscription',
    name: 'ExamRangeSubscription',
    component: () => import('@/views/php/Subscription/ExamRangeSubscription.vue')
  },
  { // 難易度參數設定
    path: '/ExamPaperSettingSubscription',
    name: 'ExamPaperSettingSubscription',
    component: () => import('@/views/php/Subscription/ExamPaperSettingSubscription.vue')
  },
  { // 作答畫面
    path: '/StartExamSubscription',
    name: 'StartExamSubscription',
    component: () => import('@/views/php/Subscription/StartExamSubscription.vue')
  },
  {
    // 成績單
    path: '/UserExamPaperSubscription',
    name: 'UserExamPaperSubscription',
    component: () => import('@/views/php/Subscription/UserExamPaperSubscription.vue')
  },
  /*---e評量 end---*/
  /*---解惑平台 Start---*/
  {
    // 解惑平台
    path: '/QuestionListSubscription',
    name: 'QuestionListSubscription',
    component: () => import('@/views/php/Subscription/QuestionListSubscription.vue')
  },
  /*---解惑平台 end---*/
];
    
export default Subscription;